import "./App.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { faGoogle, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ScrollspyNav from "react-scrollspy-nav";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import ListGroup from "react-bootstrap/ListGroup";
import googlecloud from "./google-cloud.svg";

function App() {
  return (
    <div>
      <Container fluid="xs">
        <Stack
          gap={3}
          className="row vh-100 justify-content-center align-items-center"
        >
          <Row className="justify-content-center">
            <Col md={4} xs={12}>
              <Card>
                <Card.Body>
                  <Card.Title>Hello world!</Card.Title>
                  <Card.Text>
                    My Name is{" "}
                    <span className="fw-bold text-primary">Ozan</span>.
                    <br />
                    <br />I am working at
                    <br />
                    <a
                      target="_blank"
                      className="text-decoration-none"
                      href="https://globalit.com.tr/"
                      rel="noreferrer"
                    >
                      <span className="fw-bold text-secondary">Global</span>{" "}
                      <span className="fw-bold text-primary">IT</span>{" "}
                      <span className="fw-bold text-secondary">International</span>
                    </a>
                    <br/>as <span class="fw-bold">Data Team Leader</span>
                  </Card.Text>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/in/oznozkan/"
                        className="text-decoration-none align-items-center"
                      >
                        <FontAwesomeIcon
                          icon={faLinkedin}
                          size="1x"
                          fixedWidth
                          style={{ color: "#0a66c2" }}
                        />{" "}
                        LinkedIn Profile
                      </a>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.credly.com/users/ozanozkan"
                        className="text-decoration-none align-items-center"
                      >
                        <img
                          src={googlecloud}
                          className="fa-fw fa-1x"
                          alt="google cloud"
                        />{" "}
                        Certificates
                      </a>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://partner.cloudskillsboost.google/public_profiles/74cd4e1e-80d4-4e43-a603-d0e0044467c4"
                        className="text-decoration-none align-items-center"
                      >
                        <img
                          src={googlecloud}
                          className="fa-fw fa-1x"
                          alt="google cloud"
                        />{" "}
                        Cloud Skills Profile
                      </a>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
                <ListGroup className="list-group-flush"></ListGroup>
                <Card.Body>
                  <Card.Link className="text-decoration-none align-items-center" href="mailto:ozan@ozanozkan.dev">
                    <FontAwesomeIcon icon={faEnvelope} size="1x" fixedWidth />
                    Contact Me
                  </Card.Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Stack>
      </Container>
    </div>
  );
}

export default App;
